/* eslint-disable prefer-arrow/prefer-arrow-functions*/
import {
    AgGridHeaderComponent,
} from '@shared/components/ag-grid-components/column-headers/ag-grid-header/ag-grid-header.component';
import { GridColumn, GridFormat, GroupedGridColumn } from '@models/lookup';
import {
    currencyFormatter,
    numberFormatter,
    percentFormatter,
    dateFormatter,
    getDisplayText, RA_COLORS, isClosedOutRate, hasProperty,
} from '../functions/helpers';
import {
    ColGroupDef,
    ColDef,
    ICellRendererParams,
    CellClassParams,
    ValueSetterParams,
    ValueGetterParams,
    ColSpanParams,
} from 'ag-grid-community';
import { ExcelStyle, ITooltipParams, Column } from 'ag-grid-community';
import { AgGridGroupHeaderComponent } from
    '@shared/components/ag-grid-components/column-headers/ag-grid-group-header/ag-grid-group-header.component';
import { get, set } from 'lodash';
import { CustomCellRenderers } from '@models/ag-grid';

/* eslint-disable id-blacklist */
export const FORMAT_TYPES: { [key: string]: (value: number, format?: string) => string } = {
    currency: currencyFormatter,
    number: numberFormatter,
    percent: percentFormatter,
    date: dateFormatter,
    text: (value) => String(value),
};
/* eslint-enable id-blacklist */


export const excelStyles: ExcelStyle[] = [
    {
        id: 'header',
        interior: {
            color: RA_COLORS.raPrimaryBlue,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: true,
            color: RA_COLORS.raTextWhite,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: {
            horizontal: 'Center',
            vertical: undefined,
            verticalText: undefined,
            indent: undefined,
            readingOrder: undefined,
            shrinkToFit: undefined,
            rotate: undefined,
            wrapText: undefined,
        },
        borders: {
            borderLeft: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderRight: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderTop: undefined,
            borderBottom: undefined,
        },
        numberFormat: undefined,
        protection: undefined,
    },
    {
        id: 'political-summary-header-group',
        interior: {
            color: RA_COLORS.raLightPurple,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: true,
            color: RA_COLORS.black,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: {
            horizontal: 'Center',
            vertical: undefined,
            verticalText: undefined,
            indent: undefined,
            readingOrder: undefined,
            shrinkToFit: undefined,
            rotate: undefined,
            wrapText: undefined,
        },
        borders: {
            borderLeft: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderRight: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderTop: undefined,
            borderBottom: undefined,
        },
        numberFormat: undefined,
        protection: undefined,
    },
    {
        id: 'pcode-header-group',
        interior: {
            color: RA_COLORS.raPrimaryRed,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: true,
            color: RA_COLORS.raTextWhite,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: {
            horizontal: 'Center',
            vertical: undefined,
            verticalText: undefined,
            indent: undefined,
            readingOrder: undefined,
            shrinkToFit: undefined,
            rotate: undefined,
            wrapText: undefined,
        },
        borders: {
            borderLeft: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderRight: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderTop: undefined,
            borderBottom: undefined,
        },
        numberFormat: undefined,
        protection: undefined,
    },
    {
        id: 'agency-benchmark-group',
        interior: {
            color: RA_COLORS.raSemiFadedBlue,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: true,
            color: RA_COLORS.black,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: {
            horizontal: 'Center',
            vertical: undefined,
            verticalText: undefined,
            indent: undefined,
            readingOrder: undefined,
            shrinkToFit: undefined,
            rotate: undefined,
            wrapText: undefined,
        },
        borders: {
            borderLeft: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderRight: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderTop: undefined,
            borderBottom: undefined,
        },
        numberFormat: undefined,
        protection: undefined,
    },
    {
        id: 'category-benchmark-group',
        interior: {
            color: RA_COLORS.raPrimaryRed,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: true,
            color: RA_COLORS.raTextWhite,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: {
            horizontal: 'Center',
            vertical: undefined,
            verticalText: undefined,
            indent: undefined,
            readingOrder: undefined,
            shrinkToFit: undefined,
            rotate: undefined,
            wrapText: undefined,
        },
        borders: {
            borderLeft: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderRight: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderTop: undefined,
            borderBottom: undefined,
        },
        numberFormat: undefined,
        protection: undefined,
    },
    {
        id: 'station-benchmark-group',
        interior: {
            color: RA_COLORS.raPrimaryGreen,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: true,
            color: RA_COLORS.black,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: {
            horizontal: 'Center',
            vertical: undefined,
            verticalText: undefined,
            indent: undefined,
            readingOrder: undefined,
            shrinkToFit: undefined,
            rotate: undefined,
            wrapText: undefined,
        },
        borders: {
            borderLeft: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderRight: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
            borderTop: undefined,
            borderBottom: undefined,
        },
        numberFormat: undefined,
        protection: undefined,
    },
    {
        id: 'no-line-header',
        interior: {
            color: RA_COLORS.raPrimaryBlue,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: true,
            color: RA_COLORS.raTextWhite,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: {
            horizontal: 'Center',
            vertical: undefined,
            verticalText: undefined,
            indent: undefined,
            readingOrder: undefined,
            shrinkToFit: undefined,
            rotate: undefined,
            wrapText: undefined,
        },
        borders: undefined,
        numberFormat: undefined,
        protection: undefined,
    },
    {
        id: 'ra-lightest-gray',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.darkerBackground,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'ra-lightest-gray-export',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.darkerBackground,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'non-primary-metric',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.darkerBackground,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'primary-metric',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.darkerBackground,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: true,
            color: undefined,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'white-background',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.raTextWhite,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'white-background-key',
        font: {
            bold: true,
            color: undefined,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.raTextWhite,
            pattern: 'Solid',
            patternColor: undefined,
        },
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'ra-faded-blue',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.raFadedBlue,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'ra-faded-green',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.selloutLowerLimitBackground,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'ra-faded-red',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.raFadedRed,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'currency-1.2-2-class',
        numberFormat: { format: '$#,##0.00_);($#,##0.00)' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'currency-1.0-1-class',
        numberFormat: { format: '$#,##0_);($#,##0.0)' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'currency-1.0-0-class',
        numberFormat: { format: '$#,##0_);($#,##0)' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'percent-1.2-2-class',
        numberFormat: { format: '0.00%' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'percent-1.0-0-class',
        numberFormat: { format: '0%' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'percent-1.0-1-class',
        numberFormat: { format: '0.0%' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'percent-1.1-1-class',
        numberFormat: { format: '0.0%' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'number-1.2-2-class',
        numberFormat: { format: '0.00' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'number-1.1-1-class',
        numberFormat: { format: '0.0' },

        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'number-1.0-0-class',
        numberFormat: { format: '0' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'number-1.0-1-class',
        numberFormat: { format: '0.0' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'number-1.0-2-class',
        numberFormat: { format: '0.00' },
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'gray-background-key',
        font: {
            bold: true,
            color: undefined,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.darkerBackground,
            pattern: 'Solid',
            patternColor: undefined,
        },
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'gray-background',
        font: undefined,
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.darkerBackground,
            pattern: 'Solid',
            patternColor: undefined,
        },
        alignment: {
            wrapText: false,
            vertical: undefined,
            indent: undefined,
            verticalText: undefined,
            shrinkToFit: undefined,
            horizontal: undefined,
            readingOrder: undefined,
            rotate: undefined,
        },
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'justify-center',
        font: undefined,
        numberFormat: undefined,
        interior: undefined,
        alignment: {
            wrapText: false,
            vertical: undefined,
            indent: undefined,
            verticalText: undefined,
            shrinkToFit: undefined,
            horizontal: 'Center',
            readingOrder: undefined,
            rotate: undefined,
        },
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'excel-metric-value',
        font: undefined,
        numberFormat: undefined,
        interior: undefined,
        alignment: {
            wrapText: false,
            vertical: undefined,
            indent: undefined,
            verticalText: undefined,
            shrinkToFit: undefined,
            horizontal: 'Right',
            readingOrder: undefined,
            rotate: undefined,
        },
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'cell-renderer-framework',
        font: undefined,
        numberFormat: undefined,
        interior: undefined,
        alignment: {
            wrapText: false,
            vertical: undefined,
            indent: undefined,
            verticalText: undefined,
            shrinkToFit: undefined,
            horizontal: 'Left',
            readingOrder: undefined,
            rotate: undefined,
        },
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'excel-highlight-higher',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.selloutLowerLimitBackground,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'excel-highlight-lower',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.selloutUpperLimitBackground,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'last-col-in-group',
        numberFormat: undefined,
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: {
            borderLeft: undefined,
            borderBottom: undefined,
            borderTop: undefined,
            borderRight: {
                lineStyle: 'Continuous',
                color: RA_COLORS.brightRed,
                weight: 1,
            },
        },
        protection: undefined,
    },
    {
        id: 'alert#fcddc9',
        numberFormat: undefined,
        interior: {
            color: '#fcddc9',
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'alert#ffffd8',
        numberFormat: undefined,
        interior: {
            color: '#ffffd8',
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'alert#d4ebd5',
        numberFormat: undefined,
        interior: {
            color: '#d4ebd5',
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'alert#f2d9d5',
        numberFormat: undefined,
        interior: {
            color: '#f2d9d5',
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'alert#B7E9F7',
        numberFormat: undefined,
        interior: {
            color: '#B7E9F7',
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'pcode-cell-border',
        numberFormat: undefined,
        interior: undefined,
        font: undefined,
        alignment: undefined,
        borders: {
            borderLeft: {
                lineStyle: 'Continuous',
                color: RA_COLORS.raSupportGray,
                weight: 2,
            },
            borderRight: {
                lineStyle: 'Continuous',
                color: RA_COLORS.raSupportGray,
                weight: 2,
            },
            borderTop: {
                lineStyle: 'Continuous',
                color: RA_COLORS.raSupportGray,
                weight: 2,
            },
            borderBottom: {
                lineStyle: 'Continuous',
                color: RA_COLORS.raSupportGray,
                weight: 2,
            },
        },
        protection: undefined,
    },
    {
        id: 'config-dont-edit',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.black,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'political-excel-export',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.raLightOrange,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: undefined,
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'closed-out-rate',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.raClosedOutRate,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: undefined,
            color: RA_COLORS.raTextWhite,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'ra-primary-red',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.raPrimaryRed,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: undefined,
            color: RA_COLORS.raTextWhite,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'ra-primary-yellow',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.warning,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: undefined,
            color: RA_COLORS.raTextWhite,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
    {
        id: 'ra-primary-green',
        numberFormat: undefined,
        interior: {
            color: RA_COLORS.raPrimaryGreen,
            pattern: 'Solid',
            patternColor: undefined,
        },
        font: {
            bold: undefined,
            color: RA_COLORS.raTextWhite,
            fontName: undefined,
            italic: undefined,
            outline: undefined,
            shadow: undefined,
            size: undefined,
            strikeThrough: undefined,
            underline: undefined,
            verticalAlign: undefined,
            charSet: undefined,
            family: undefined,
        },
        alignment: undefined,
        borders: undefined,
        protection: undefined,
    },
];

export const TOTAL_ROW_ID = -1;
export const TOTAL_WEEKLY_ROW_ID = -2;

export function getGroupedColumnDefs(
    groupedColumns: GroupedGridColumn[],
    customCellRenderers: CustomCellRenderers = {},
    isLocked: () => boolean = () => false,
    showCurrentOnly = false,
): ColGroupDef[] {
    const colGroupDef: ColGroupDef[] = [];
    groupedColumns.forEach((groupedColumn, index) => {
        const colDefs: ColDef[] = getColumnDefs(groupedColumn.columns, customCellRenderers, isLocked, showCurrentOnly);
        colGroupDef.push({
            headerName: groupedColumn.name,
            headerGroupComponent: AgGridGroupHeaderComponent,
            headerGroupComponentParams: {
                name: groupedColumn.name,
                inPoliticalWindow: groupedColumn?.inPoliticalWindow,
            },
            children: colDefs,
            groupId: index.toString(),
        });
    });
    return colGroupDef;
}

export function getColumnDefs(
    columns: GridColumn[],
    customCellRenderers: CustomCellRenderers = {},
    isLocked: () => boolean = () => false,
    showCurrentOnly = false,
): ColDef[] {
    const colDefs: ColDef[] = [];

    columns.forEach(column => {

        const customCellRenderer = customCellRenderers[column.field];
        let cellRendererFramework;
        let cellEditorFramework;
        let cellRendererParams;
        let cellEditorParams;
        let headerComponent;
        let headerComponentParams;

        if (customCellRenderer) {
            cellRendererFramework = customCellRenderer.cellRendererFramework;
            cellEditorFramework = customCellRenderer.cellEditorFramework;
            headerComponent = customCellRenderer.headerComponent;
            cellRendererParams = cellRendererFramework ? {
                ...customCellRenderer.params,
                isEditable: customCellRenderer.params?.isEditable === undefined
                    ? column.isEditable && !isLocked() && !(column.can_edit === false)
                    : customCellRenderer.params?.isEditable,
            } : undefined;
            cellEditorParams = cellEditorFramework ? {
                ...customCellRenderer.params,
                isEditable: customCellRenderer.params?.isEditable === undefined
                    ? column.isEditable && !isLocked() && !(column.can_edit === false)
                    : customCellRenderer.params?.isEditable,
            } : undefined;
            headerComponentParams = customCellRenderer.headerParams;
        }
        const cellRenderer = !customCellRenderer
            ? (params: ICellRendererParams) => params.data?.format
                ? columnRenderer(params, params.data.format)
                : column.format
                    ? columnRenderer(params, column.format)
                    : columnRenderer(params, null)
            : null;

        const col: ColDef = {
            headerComponent: headerComponent || AgGridHeaderComponent,
            headerComponentParams: headerComponentParams || {
                name: column.name,
                icon: column.isEditable && !isLocked() ? 'fas fa-edit' : '',
                editable: column.isEditable && !isLocked() && !(column.can_edit === false),
                format: column.format,
                isNonNegative: column.isNonNegative,
                showCurrentOnly,
            },
            field: column.field,
            hide: !(column.display ?? true),
            lockVisible: column.lockVisible ?? false,
            editable: (params) => editable(params, column, cellRendererFramework, isLocked, col.enableValue),
            cellRenderer,
            cellRendererFramework,
            cellRendererParams,
            cellEditorFramework: cellEditorFramework ? cellEditorFramework : undefined,
            cellEditorParams: cellEditorFramework ? cellEditorParams : undefined,
            columnGroupShow: column.columnGroupShow,
            valueSetter,
            valueGetter,
            colSpan: (params: ColSpanParams) => get(params.data, params.colDef.field)?.colSpan ?? 1,
            cellClass: column.format
                ? (params: CellClassParams) => cellClass(params, column.format)
                : (params: CellClassParams) => params.data?.format
                    ? cellClass(params, params.data.format)
                    : cellClass(params, undefined),
            cellClassRules: cellClassRules(),
            tooltipValueGetter: (params: ITooltipParams) => get(params.node.data, (params.colDef as ColDef).field)?.displayTooltip,
        };
        if (hasProperty(column, 'sortable')) {
            col.sortable = column.sortable;
        }
        colDefs.push(col);
    });
    return colDefs;
}

export function valueSetter(params: ValueSetterParams): boolean {
    const data = get(params.data, params.colDef.field);
    if (!data) {
        return set(params.data, params.colDef.field, params.newValue);
    }
    if (hasProperty(data, 'value')) {
        if (hasProperty(data.value, 'current')) {
            return data.value.current = params.newValue;
        } else if (hasProperty(data.value, 'new')) {
            return data.value.new = params.newValue;
        }
    } else if (hasProperty(data, 'current')) {
        return data.current = params.newValue;
    } else if (hasProperty(data, 'new')) {
        return data.new = params.newValue;
    }
    return set(params.data, params.colDef.field, params.newValue);
}

export function valueGetter(params: ValueGetterParams): string | number {
    const root = get(params.data, params.colDef.field);
    let dataValue = hasProperty(root, 'value') ? root.value : root;
    if (hasProperty(dataValue, 'current')) {
        dataValue = dataValue.current;
    } else if (hasProperty(dataValue, 'name')) {
        dataValue = getDisplayText(dataValue);
    }
    return dataValue;
}

export function columnRenderer(params: ICellRendererParams, rowColFormat: GridFormat): string {
    const format: GridFormat = get(params.data, params.colDef.field)?.format || rowColFormat;
    const rendererValue = params.value;
    const root = get(params.data, params.colDef.field);
    const dataValue = root?.value ?? root;

    if (dataValue?.current === null && dataValue?.session_start !== null) {
        return '';
    }
    // This is currently used for Cablenet DR Scenario in Dashboard. 'is_override' variable comes from the API
    if (dataValue?.is_override) {
        let stringCurrent = dataValue.current;
        if (format?.format) {
            const current = Number(dataValue.current);
            stringCurrent = FORMAT_TYPES[format.type](
                getFormatValue(current, format.type), format.format,
            );
        }
        return '<b>' + stringCurrent + '*<b/>';
    }

    if (
        typeof dataValue === 'string' && isNaN(Number(dataValue)) && params.colDef.headerComponentParams.format?.type !== 'date') {
        return dataValue;
    }
    if (params.colDef.headerComponentParams?.isNonNegative
        && (
            dataValue == null && rendererValue == null
            && (
                dataValue?.original == null
                && dataValue?.current == null
            )
        )) {
        return '-';
    }
    if (format?.format) {
        if (
            dataValue &&
            (
                dataValue.original != null
                || dataValue.session_start != null
            )
        ) {
            const original = Number(dataValue.original);
            const current = Number(dataValue.current);
            const sessionStart = Number(dataValue.session_start);
            const formatOriginal = FORMAT_TYPES[format.type](
                getFormatValue(original, format.type), format.format,
            );
            const formatCurrent = FORMAT_TYPES[format.type](
                getFormatValue(current, format.type), format.format,
            );
            const formatSessionStart = FORMAT_TYPES[format.type](
                getFormatValue(sessionStart, format.type), format.format,
            );
            const showCurrentOnly = params.colDef.headerComponentParams.showCurrentOnly || original === current;
            const compareSessionOverridesOnly = params.colDef.headerComponentParams.compareSessionOverridesOnly;
            if (!showCurrentOnly) {
                if (!root.collapseOverride && dataValue.original != null && original !== current && !compareSessionOverridesOnly) {
                    const overrideClass: string =
                        original < current
                            ? 'higher-override'
                            : 'lower-override';
                    return `<span class="overridden-value">${formatOriginal}&rarr;</span>` +
                        `<span class="${overrideClass}">${formatCurrent}</span>`;
                } else if (root.collapseOverride
                    && dataValue.session_start != null
                    && sessionStart === current
                    && !compareSessionOverridesOnly) {
                    const overrideClass: string =
                        original < current
                            ? 'higher-override'
                            : 'lower-override';
                    return `<span class="overridden-value">${formatOriginal}&rarr;</span>` +
                        `<span class="${overrideClass}">${formatCurrent}</span>`;
                } else if (dataValue.session_start != null && sessionStart !== current && compareSessionOverridesOnly) {
                    const overrideClass: string =
                        sessionStart < current
                            ? 'higher-override'
                            : 'lower-override';
                    return `<span class="overridden-value">${formatSessionStart}&rarr;</span>` +
                        `<span class="${overrideClass}">${formatCurrent}</span>`;
                }
            }
            return current || current === 0 || rendererValue === '0'
                ? formatCurrent
                : '';
        }
    }
    return (rendererValue || rendererValue === 0) && format
        ? format.format
            ? FORMAT_TYPES[format.type](
                getFormatValue(rendererValue, format.type), format.format,
            )
            : FORMAT_TYPES[format.type](
                getFormatValue(rendererValue, format.type),
            )
        : getDisplayText(params.value);
}

export function getFormatValue(value, formatType: string) {
    return formatType === 'percent' ? value / 100 : value;
}

export function cellClass(params: CellClassParams, format: GridFormat): string[] {
    // let dataValue;
    const fieldValue = get(params.data, params.colDef.field);
    const dataValue = fieldValue?.value ?? fieldValue;
    const isString = typeof dataValue === 'string';

    const classes = ['cell-data'];

    const colParent = params.columnApi.getColumn(params.colDef.field)?.getParent();
    if ((colParent?.getChildren()[colParent?.getChildren().length - 1] as Column)?.getColDef().field === params.colDef.field) {
        classes.push('last-col-in-group');
    }

    const columnColSpan = typeof params.colDef.colSpan === 'function'
        ? params.colDef.colSpan(params as unknown as ColSpanParams)
        : null;

    if (
        ((!fieldValue?.colSpan && !columnColSpan) || fieldValue?.colSpan === 1 || columnColSpan === 1)
        && (format?.type === 'number' || format?.type === 'currency' || format?.type === 'percent')
        && !isString
    ) {
        classes.push('justify-flex-end');
    } else {
        classes.push('justify-center');
    }

    if (params.colDef?.cellRendererFramework) {
        classes.push('cell-renderer-framework');
    }

    // These are only used for excel exporting
    if (params.data?.format) {
        if (typeof dataValue !== 'string' || format.type === 'date') {
            classes.push(`${params.data.format.type}-${params.data.format.format}-class`);
            classes.push('excel-metric-value');
        } else {
            classes.push('text');
        }
    } else {
        if (format && (typeof (dataValue) !== 'string' || format.type === 'date')) {
            classes.push(`${format.type}-${format.format}-class`);
            classes.push('excel-metric-value');
        } else {
            classes.push('text');
        }
    }
    if (hasProperty(dataValue, 'original') && hasProperty(dataValue, 'current')) {
        if (dataValue.original > dataValue.current) {
            classes.push('excel-highlight-lower');
        } else if (dataValue.original < dataValue.current) {
            classes.push('excel-highlight-higher');
        }
    }

    return classes;
}

export function cellClassRules(): { [cssClassName: string]: string | ((params: CellClassParams) => boolean) } {
    const classes = {};
    classes['ra-lightest-gray-export'] = (params: CellClassParams) => {
        const oddRow = params.node.rowIndex % 2 === 1;
        const closedOutRate = isClosedOutRate(params);
        return oddRow && !closedOutRate;
    };
    return classes;
}

export function editable(params, column: GridColumn, cellRendererFramework, isLocked: () => boolean, pCodeEditable): boolean {
    if (isLocked() && !column.field.includes('pcode')) {
        return false;
    }
    if (cellRendererFramework) {
        return false;
    }
    if (column.can_edit === false) {
        return false;
    }
    const root = get(params.data, params.colDef.field);
    if (hasProperty(root, 'isEditable') && ((root.isPcode && pCodeEditable) || (!root.isPcode))) {
        return root.isEditable;
    }
    return column.isEditable
        && !cellRendererFramework
        && !isLocked();
}
