import { Component, OnInit } from '@angular/core';
import { PushNotificationService } from '@services/push-notification/push-notification.service';
import { AuthService } from '@services/auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    title = 'media-shrike-ui';
    constructor(
        private authSvc: AuthService,
        private pushNotificationService: PushNotificationService,

    ) { }
    ngOnInit() {
        if(this.authSvc.getCustomerIds().customerId) {
            this.pushNotificationService.subscribeToNotification();
        }
    }
}
