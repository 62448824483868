import { Component, Inject, OnInit } from '@angular/core';
import { AgGridApiDirective } from '@shared/helpers/ag-grid/ag-grid';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { applyFilters, getFilters } from '@shared/helpers/functions/filter-helpers';
import { Grid, StringLookupItem } from '@models/lookup';
import { AdvertiserInsightsService } from '@services/advertiser-insights/advertiser-insights.service';
import { cellClassRules, getColumnDefs } from '@shared/helpers/ag-grid/ag-grid-builder';
import { ColDef } from 'ag-grid-community';
import { AdvertiserInsightsOrderRow } from '@models/advertiser-insights/advertiser-insights';
import { AdvertiserInsightsOrdersFilter } from '@models/filter';
import { NotificationService } from '@services/notification/notification.service';
import * as moment from 'moment';
import { AgGridCheckboxHeaderComponent } from '@shared/components/ag-grid-components/column-headers/ag-grid-checkbox-header/ag-grid-checkbox-header.component';
import { CheckboxCellComponent } from '@shared/components/ag-grid-components/cell-renderers/checkbox-cell/checkbox-cell.component';
import { AdvertiserInsightsOrdersFilters } from '@models/advertiser-insights/advertiser-insights-orders-filters';
import { CheckBoxCellParams } from '@models/ag-grid';
import { UiConfig } from '@models/config';

@Component({
    selector: 'app-advertiser-insights-orders',
    templateUrl: './advertiser-insights-orders.component.html',
    styleUrls: ['./advertiser-insights-orders.component.scss'],
})
export class AdvertiserInsightsOrdersComponent extends AgGridApiDirective<AdvertiserInsightsOrderRow> implements OnInit {

    filters: AdvertiserInsightsOrdersFilters;
    isFiltering = false;
    config: UiConfig;

    noRowsOverlay = 'No orders are available for the current filter selection.\nPlease update filters and click Apply Filters to view the Orders table';

    defaultColDef: ColDef = {
        suppressMovable: true,
    };

    selectCol: ColDef = {
        headerComponent: AgGridCheckboxHeaderComponent,
        headerComponentParams: {
            name: ' ',
            editable: false,
            checked: true,
            onChecked: (isChecked) => this.toggleSelectAll(isChecked, this.rowData, this.selectCol.field),
        },
        field: 'included',
        cellClass: ['justify-center'],
        cellClassRules: cellClassRules(),
        editable: false,
        sortable: false,
        pinned: false,
        width: 15,
        cellRendererFramework: CheckboxCellComponent,
        cellRendererParams: { callBack: (params: CheckBoxCellParams) => this.selectRowForSelectAll(params, this.rowData) },
    };

    rowData: AdvertiserInsightsOrderRow[];
    colDefs: ColDef[];

    constructor(
        public dialogRef: MatDialogRef<AdvertiserInsightsOrdersComponent>,
        private advertiserInsightsSvc: AdvertiserInsightsService,
        private snackBarSvc: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: {
            orders: {
                options: StringLookupItem[];
                selected: StringLookupItem[];
            };
            channels: number[];
            config: UiConfig;
        },
    ) {
        super();
        this.config = data.config;
    }

    ngOnInit(): void {
        getFilters(new AdvertiserInsightsOrdersFilters(
            this.advertiserInsightsSvc,
            this.config,
            this.data.orders.options,
            this.data.channels,
        ))
            .subscribe(filters =>
                this.filters = filters
            , error => {
                console.error(error);
                this.snackBarSvc.openSnackBar('Failed to load filters', 'error');
            });
        this.loadOrders(
            {} as AdvertiserInsightsOrdersFilter,
        );
    }

    closeDialog(): void {
        this.dialogRef.close();
    }

    save(): void {
        this.dialogRef.close(this.getSelectedRows(this.rowData, this.selectCol.field).map(row => row.orderNumber));
    }

    loadOrders(filter: AdvertiserInsightsOrdersFilter): void {
        this.advertiserInsightsSvc.getOrders(
            this.data.orders.options.map(order => order.id),
            this.data.channels,
            filter,
        ).subscribe((grid: Grid<AdvertiserInsightsOrderRow>) => {
            const previousSelection: string[] = this.rowData?.length > 0
                ? this.getSelectedRows(this.rowData, this.selectCol.field).map(row => row.orderNumber)
                : this.data.orders.selected.map(order => order.id);
            this.rowData = grid.data;
            this.rowData.forEach(row => row.included = previousSelection.includes(row.orderNumber));
            this.colDefs = getColumnDefs(grid.columns);
            this.colDefs.forEach((column: ColDef) => {
                if (column.field === 'flightStart' || column.field === 'flightEnd') {
                    column.comparator = ((valueA: string, valueB: string) => {
                        const compA = moment(valueA);
                        const compB = moment(valueB);
                        if (compA.isBefore(compB)) {
                            return 1;
                        }
                        return -1;
                    });
                    if (column.field === 'flightStart') {
                        column.sort = 'desc';
                    }
                }
            });
            this.colDefs.unshift(this.selectCol);
            this.isFiltering = false;
        }, error => {
            this.snackBarSvc.openSnackBar('Failed to Load Orders', 'error');
            console.error(error);
            this.isFiltering = false;
        });
    }

    applyFilters(): void {
        if (!this.isFiltering) {
            this.isFiltering = true;
            this.isFiltering = applyFilters<AdvertiserInsightsOrdersFilter>(this.filters, (filters) => this.loadOrders(filters.template));
        }
    }
}
