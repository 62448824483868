/* eslint-disable max-len*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavComponent } from './components/global/nav/nav.component';
import { AppMaterialModule } from '../app-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { DateSelectComponent } from './components/filters/date-select/date-select.component';
import { CheckboxCellComponent } from './components/ag-grid-components/cell-renderers/checkbox-cell/checkbox-cell.component';
import { FilterSetComponent } from './components/filters/filter-set/filter-set.component';
import { FilterInputComponent } from './components/filters/filter-input/filter-input.component';
import { NotificationComponent } from './components/global/notification/notification.component';
import { DateRangeSelectComponent } from './components/filters/date-range-select/date-range-select.component';
import { SidenavComponent } from './components/global/sidenav/sidenav.component';
import { DialogContainerComponent } from './components/wrappers/dialog-container/dialog-container.component';
import { ExpansionPanelComponent } from './components/wrappers/expansion-panel/expansion-panel.component';
import { DisplayTextPipe } from './pipes/display-text/display-text.pipe';
import { AgGridHeaderComponent } from './components/ag-grid-components/column-headers/ag-grid-header/ag-grid-header.component';
import { DateSelectCellComponent } from './components/ag-grid-components/cell-renderers/date-select-cell/date-select-cell.component';
import { FindPipe } from './pipes/find/find.pipe';
import { SearchFilter } from './pipes/search-filter/search-filter.pipe';
import { DynamicPipe } from './pipes/dynamic/dynamic.pipe';
import { NoCommaPipe } from './pipes/noComma/no-comma.pipe';
import { FilterWidgetComponent } from './components/filters/filter-widget/filter-widget.component';
import { WizardWrapperComponent } from './components/wrappers/wizard-wrapper/wizard-wrapper.component';
import { AgGridGroupHeaderComponent } from './components/ag-grid-components/column-headers/ag-grid-group-header/ag-grid-group-header.component';
import { TextInputCellEditorComponent } from './components/ag-grid-components/cell-renderers/text-input-cell-editor/text-input-cell-editor.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EditConflictCellComponent } from './components/edit-conflict-cell/edit-conflict-cell.component';
import { BigMoneyPipe } from '@shared/pipes/big-money/big-money.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SelectFilterCellEditorComponent } from '@shared/components/ag-grid-components/cell-renderers/select-filter-cell-editor/select-filter-cell-editor.component';
import { RadioButtonCellComponent } from '@shared/components/ag-grid-components/cell-renderers/radio-button-cell/radio-button-cell.component';
import { ModalEditPopupComponent } from './components/modal-edit-popup-component/modal-edit-popup.component';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { TextDialogComponent } from './components/text-dialog/text-dialog.component';
import { SelectFilterCellComponent } from '@shared/components/ag-grid-components/cell-renderers/select-filter-cell/select-filter-cell.component';
import { InfoGridPopupComponent } from './components/info-grid-popup/info-grid-popup.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridCheckboxHeaderComponent } from '@shared/components/ag-grid-components/column-headers/ag-grid-checkbox-header/ag-grid-checkbox-header.component';
import { UnescapePipe } from './pipes/unescape/unescape.pipe';
import { ButtonCellComponent } from '@shared/components/ag-grid-components/cell-renderers/button-cell/button-cell.component';
import { MultiSelectFilterCellComponent } from '@shared/components/ag-grid-components/cell-renderers/multi-select-filter-cell/multi-select-filter-cell.component';
import { TierMultiplierGridComponent } from './components/tier-multiplier-grid/tier-multiplier-grid.component';
import { NavLastDataUpdateComponent } from './components/global/nav-last-data-update/nav-last-data-update.component';
import { IsCheckboxFilterPipe } from '@shared/pipes/filter-type-pipes/is-checkbox-filter/is-checkbox-filter.pipe';
import { IsSelectFilterPipe } from '@shared/pipes/filter-type-pipes/is-select-filter/is-select-filter.pipe';
import { IsMultiselectFilterPipe } from '@shared/pipes/filter-type-pipes/is-multiselect-filter/is-multiselect-filter.pipe';
import { IsDeferrableFilterPipe } from '@shared/pipes/filter-type-pipes/is-deferrable-filter/is-deferrable-filter.pipe';
import { IsRadioFilterPipe } from '@shared/pipes/filter-type-pipes/is-radio-filter/is-radio-filter.pipe';
import { IsDateFilterPipe } from '@shared/pipes/filter-type-pipes/is-date-filter/is-date-filter.pipe';
import { IsDateRangeFilterPipe } from '@shared/pipes/filter-type-pipes/is-daterange-filter/is-daterange-filter.pipe';
import { IsNumberFilterPipe } from '@shared/pipes/filter-type-pipes/is-number-filter/is-number-filter.pipe';
import { IsStringFilterPipe } from '@shared/pipes/filter-type-pipes/is-string-filter/is-string-filter.pipe';
import { IsBooleanFilterPipe } from '@shared/pipes/filter-type-pipes/is-boolean-filter/is-boolean-filter.pipe';
import {
    IsPoliticalWindowsConfigTierFilterPipe,
} from '@shared/pipes/filter-type-pipes/is-political-windows-config-tier-filter/is-political-windows-config-tier-filter.pipe';
import { StringPipe } from '@shared/pipes/string/string.pipe';
import { PopupCellComponent } from './components/ag-grid-components/cell-renderers/popup-cell/popup-cell.component';
import { RemoveAriaOwnsDirective } from './remove-aria-owns.directive';
import { TimeSelectComponent } from './components/filters/time-select/time-select.component';
import { IsTimeFilterPipe } from '@shared/pipes/filter-type-pipes/is-time-filter/is-time-filter.pipe';
import { NbInputModule, NbLayoutModule, NbTimepickerModule } from '@nebular/theme';
import { NbMomentDateModule } from '@nebular/moment';
import { AutocompleteComponent } from './components/filters/autocomplete/autocomplete.component';
import {
    IsAutocompleteFilterPipe,
} from '@shared/pipes/filter-type-pipes/is-autocomplete-filter/is-autocomplete-filter.pipe';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { CopyURLDialogComponent } from './components/copy-url-dialog/copy-url-dialog.component';
import { EmailReportsPopUpComponent } from '@shared/components/email-reports-pop-up/email-reports-pop-up.component';

@NgModule({
    declarations: [
        NavComponent,
        ToggleButtonComponent,
        CheckboxCellComponent,
        DateSelectComponent,
        DateRangeSelectComponent,
        FilterInputComponent,
        FilterSetComponent,
        NotificationComponent,
        SidenavComponent,
        DialogContainerComponent,
        ExpansionPanelComponent,
        DisplayTextPipe,
        AgGridHeaderComponent,
        AgGridCheckboxHeaderComponent,
        DateSelectCellComponent,
        FindPipe,
        SearchFilter,
        ModalEditPopupComponent,
        SelectFilterCellEditorComponent,
        SelectFilterCellComponent,
        MultiSelectFilterCellComponent,
        DynamicPipe,
        NoCommaPipe,
        FilterWidgetComponent,
        RadioButtonCellComponent,
        WizardWrapperComponent,
        AgGridGroupHeaderComponent,
        TextInputCellEditorComponent,
        EditConflictCellComponent,
        BigMoneyPipe,
        ConfirmDialogComponent,
        TextDialogComponent,
        EmailReportsPopUpComponent,
        InfoGridPopupComponent,
        UnescapePipe,
        ButtonCellComponent,
        TierMultiplierGridComponent,
        NavLastDataUpdateComponent,
        IsCheckboxFilterPipe,
        IsSelectFilterPipe,
        IsMultiselectFilterPipe,
        IsDeferrableFilterPipe,
        IsRadioFilterPipe,
        IsDateFilterPipe,
        IsDateRangeFilterPipe,
        IsNumberFilterPipe,
        IsStringFilterPipe,
        IsBooleanFilterPipe,
        IsPoliticalWindowsConfigTierFilterPipe,
        StringPipe,
        TimeSelectComponent,
        IsTimeFilterPipe,
        PopupCellComponent,
        RemoveAriaOwnsDirective,
        AutocompleteComponent,
        IsAutocompleteFilterPipe,
        MessageDialogComponent,
        CopyURLDialogComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AppMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        AgGridModule,
        NbTimepickerModule,
        NbInputModule,
        NbLayoutModule,
        NbMomentDateModule,
    ],
    exports: [
        NavComponent,
        ToggleButtonComponent,
        CheckboxCellComponent,
        RadioButtonCellComponent,
        DateSelectComponent,
        DateRangeSelectComponent,
        FilterInputComponent,
        FilterSetComponent,
        NotificationComponent,
        SidenavComponent,
        DialogContainerComponent,
        ExpansionPanelComponent,
        DisplayTextPipe,
        SearchFilter,
        DateSelectCellComponent,
        ModalEditPopupComponent,
        SelectFilterCellEditorComponent,
        SelectFilterCellComponent,
        MultiSelectFilterCellComponent,
        WizardWrapperComponent,
        TierMultiplierGridComponent,
        FindPipe,
        DynamicPipe,
        NoCommaPipe,
        BigMoneyPipe,
        UnescapePipe,
        NavLastDataUpdateComponent,
        IsCheckboxFilterPipe,
        IsSelectFilterPipe,
        IsMultiselectFilterPipe,
        IsDeferrableFilterPipe,
        IsRadioFilterPipe,
        IsDateFilterPipe,
        IsDateRangeFilterPipe,
        IsNumberFilterPipe,
        IsStringFilterPipe,
        IsBooleanFilterPipe,
        IsPoliticalWindowsConfigTierFilterPipe,
        StringPipe,
        PopupCellComponent,
        RemoveAriaOwnsDirective,
        NbTimepickerModule,
        NbInputModule,
    ],
})
export class SharedModule {
}
