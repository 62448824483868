/*eslint-disable @typescript-eslint/member-ordering */

import * as moment from 'moment';
import { UrlStore } from '@shared/helpers/constants/url-store';
import {
    BooleanFilter,
    DateFilter, Filter,
    FilterTemplate,
    GetTemplateValueForSaveReport,
    MultiSelectFilter,
    SelectFilter,
} from '../filter-types';
import { ChannelItem, DaypartLookupItem, LookupItem, MarketItem, StationItem, StringLookupItem } from '../lookup';
import { Moment } from 'moment';
import { Observable, of } from 'rxjs';
import { AdvertiserInsightsService } from '@services/advertiser-insights/advertiser-insights.service';
import {
    AdvertiserInsightsDependentFiltersResponse,
    AdvertiserInsightsDependentFiltersRequest,
} from '@models/advertiser-insights/advertiser-insights';
import { cascadeToFilter } from '@shared/helpers/functions/filter-helpers';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@services/notification/notification.service';
import { UiConfig } from '@models/config';
import { cloneDeep } from 'lodash';
import { LookupV2Service } from '@services/lookup-v2/lookup-v2.service';
import { switchMap } from 'rxjs/operators';
import { getBroadcastYearEnd, getBroadcastYearStart } from '@shared/helpers/functions/helpers';
import { AdvertiserInsightsOrdersComponent } from
    '../../../rate-optics/advertiser-insights/advertiser-insights-orders/advertiser-insights-orders.component';
import {
    channelGroupsFilter,
    channelsFilter,
    daypartsFilter, endDateFilter,
    loadReport,
    marketsFilter,saveReport,
    spotLengthsFilter, spotTypesFilter, startDateFilter,
    stationsFilter,
} from '@models/base_filters';
import { FilterSetComponent } from '@shared/components/filters/filter-set/filter-set.component';
import { FilterSettings } from '@models/filter';


const SUNDAY = 0;
const MONDAY = 1;
const NEXT_SUNDAY = 7;

export class AdvertiserInsightsFilters implements FilterTemplate {
    private lockFilters = false;
    // 🚨 The order of this matters. Used to determine parents / children of our filters.
    cascadingFilters = [
        'advertisers',
        'agencies',
        'categories',
        'startDate',
        'endDate',
        'orderNumbers',
        'dayparts',
        'spotTypes',
        'spotLengths',
    ];

    private marketItems: MarketItem[] = [];
    public stationItems: StationItem[] = [];
    private channelItems: ChannelItem[] = [];
    private emptyLookupItem: MarketItem[] = [];
    public savedReportLoaded = false;
    public loadAfterCascade = false;

    constructor(
        private lookupSvc: LookupV2Service,
        public advertiserInsightsSvc: AdvertiserInsightsService,
        private matDialog: MatDialog,
        private snackBar: NotificationService,
        public config: UiConfig,
        private filterSet: FilterSetComponent,
        private onLoadCallback: () => void,
        private afterLoad = () => {
        },
    ) {
    }

    settings: FilterSettings = {
        config: this.config,
        lookupSvc: this.lookupSvc,
        hasAdvertiserInsightsData: true,
    };

    isMarketsDisabled = !this.config.roleCapability.rate_optics.general.markets;
    oneMarketAccess = false;

    channelGroup = channelGroupsFilter(this.settings)
        .OnLoad((items: LookupItem[]) => {
            if (items.length === 1) {
                this.channelGroup.Toggle(true);
                if (this.channelGroup.Value.length === 0) {
                    this.channelGroup.setValueNoOnChange(items);
                }
            }
            this.channelGroup.Validate();
        })
        .OnChange((items: LookupItem[]) => {
            if (items) {
                if (this.markets) {
                    this.markets.Value = [];
                    const newOptions = this.marketItems.filter(function(mi) {
                        return this.some(i => mi.channelGroups.flat().indexOf(i) >= 0);
                    }, items.map(i => i.id));
                    this.markets.Options(newOptions);
                    if (this.markets.Value.length === 0 && newOptions.length >= 1) {
                        this.markets.Value = newOptions;
                    }
                }
            }
        })
        .RestoreWithId(false);

    // Note: If User only has access to one market, then all market, stations and channels should be pre-selected
    markets = marketsFilter(this.settings)
        .SetName('markets')
        .OnLoad((items: MarketItem[]) => {
            if (this.marketItems.length === 0) {
                this.marketItems = cloneDeep(items);
            }
            this.oneMarketAccess = items.length === 1;
            if (this.isMarketsDisabled || this.oneMarketAccess) {
                if (!(this.markets.Value.length > 0)) {
                    this.markets.Default(items);
                }
            } else {
                if (!this.savedReportLoaded) {
                    this.markets.Default(this.emptyLookupItem);
                }
            }
        })
        .OnChange((items: LookupItem[]) => {
            // when the market changes we filter the list of stations to match
            if (this.stations && !this.markets.isOpened) {
                this.updateStations();
            }
        })
        .RestoreWithId(false);

    stations = stationsFilter(this.settings)
        .SetName('stations')
        // load the list of stations and filter by selected markets
        .OnLoad((items: StationItem[]) => {
            if (!this.savedReportLoaded) {
                // store the full list of stations as the base for filters
                if (this.stationItems.length === 0) {
                    this.stationItems = cloneDeep(items);
                }
                this.updateStations();
            }

        })
        .OnChange((items: StationItem[]) => {
            if (this.channels && !this.stations.isOpened) {
                this.updateChannels();
            }
        })
        .RestoreWithId(false);

    channels = channelsFilter(this.settings)
        .SetName('channels')
        .OnLoad((items: ChannelItem[]) => {
            if (!this.savedReportLoaded) {
                if (this.channelItems.length === 0) {
                    this.channelItems = cloneDeep(items);
                }
                this.updateChannels();
            }
        })
        .OnChange((items: LookupItem[]) => {
            if (!this.advertisers.isServerFilter && !this.channels.isOpened) {
                if (items.length > 0) {
                    this.lookupSvc.getAdvertiserInsightsAdvertisers(
                        {
                            markets: this.markets.Value.map(market => market.id),
                            stations: this.stations.Value.map(station => station.id),
                            channels: this.channels.Value.map(channel => channel.id),
                            fullAdvertiserAccess: this.config.roleCapability.rate_optics.advertiser_insights?.full_advertiser_access,
                        },
                    ).subscribe(advertisers => {
                        cascadeToFilter(this.advertisers, advertisers, []);
                    });
                } else {
                    this.advertisers.Options([]);
                    this.advertisers.Value = [];
                }
            } else {
                if (this.advertisers.Value.length > 0) {
                    this.lookupSvc.getAdvertiserInsightsAdvertisers(
                        {
                            markets: this.markets.Value.map(market => market.id),
                            stations: this.stations.Value.map(station => station.id),
                            channels: this.channels.Value.map(channel => channel.id),
                            advertisers: this.advertisers.Value.map(advertiser => advertiser.id),
                            fullAdvertiserAccess: this.config.roleCapability.rate_optics.advertiser_insights?.full_advertiser_access,
                        },
                    ).subscribe(advertisers => {
                        if (this.config.roleCapability.rate_optics.advertiser_insights?.full_advertiser_access) {
                            cascadeToFilter(this.advertisers, advertisers);
                        }
                    });
                }
            }
        })
        .RestoreWithId(false);

    advertisers = new MultiSelectFilter<StringLookupItem>('Advertisers')
        .SetName('advertisers')
        .Defer(() => {
            const fullAccess = this.config.roleCapability.rate_optics.advertiser_insights?.full_advertiser_access;
            const params = () => ({
                markets: this.markets.Value.map(market => market.id),
                stations: this.stations.Value.map(station => station.id),
                channels: this.channels.Value.map(channel => channel.id),
                fullAdvertiserAccess: fullAccess,
            });
            if (fullAccess) {
                return this.makeAdvertiserFilterServerSide(params);
            }
            return this.lookupSvc.getAdvertiserInsightsAdvertisersCount(params())
                .pipe(switchMap((count: number) => {
                    if (count > 500) {
                        return this.makeAdvertiserFilterServerSide(params);
                    } else {
                        this.advertisers.OnLoad((items: StringLookupItem[]) => {
                            this.advertisers.Value = this.advertisers.Value.length > 0 && fullAccess
                                ? items.filter(option => this.advertisers.Value.map(value => value.id).includes(option.id))
                                : [];
                        });
                        this.advertisers.Filterable(true);
                        return this.lookupSvc.getAdvertiserInsightsAdvertisers(params());
                    }
                }));
        })
        .OnChange((items: StringLookupItem[]) => {
            this.setDeferrableFiltersCascadingBehavior(this.advertisers, items);
        })
        .OnLoad((items: StringLookupItem[]) => {
            if (this.savedReportLoaded) {
                this.lockFilters = true;
                const savedAdvertisers = this.advertisers.Value;
                this.advertisers.Options(savedAdvertisers);
                this.lockFilters = false;

            } else {
                this.setDeferrableOnLoadBehavior(this.advertisers, items);
            }
        })
        .Required(true, () => 'Advertiser(s) is required')
        .Transform(advertisers => advertisers.map(advertiser => advertiser.id))
        .RestoreWithId(false)
        .Icon('fas fa-ad');

    agencies = new MultiSelectFilter<StringLookupItem>('Agencies')
        .SetName('agencies')
        .SetFilterFn((filterString: string) => {
            // If we have parent filters with data, only allow this filter's options to be searchable.
            if (this.parentFiltersHaveData(this.agencies.name)) {
                return of(this.agencies.options.filter(agency => agency.name.toLowerCase().includes(filterString)));
            } else {
                // Else, allow a global search of them so we can start at either advertiser or agency independently.
                return this.lookupSvc.getAdvertiserInsightsAgencies({
                    count: 500,
                    searchText: filterString,
                    markets: this.markets.Value.map(market => market.id),
                    stations: this.stations.Value.map(station => station.id),
                    channels: this.channels.Value.map(channel => channel.id),
                });
            }
        })
        .OnChange((items: StringLookupItem[]) => {
            this.setDeferrableFiltersCascadingBehavior(this.agencies, items);
        })
        .OnLoad((items: StringLookupItem[]) => {
            this.setDeferrableOnLoadBehavior(this.agencies, items);
        })
        .Defer(() => of([]))
        .IsServerFilter(true)
        .Transform(agencies => agencies.map(agency => agency.id))
        .RestoreWithId(true)
        .Icon('fas fa-building');

    categories = new MultiSelectFilter<StringLookupItem>('Categories')
        .SetName('categories')
        .OnChange((items: StringLookupItem[]) => {
            this.setDeferrableFiltersCascadingBehavior(this.categories, items);
        })
        .OnLoad((items: StringLookupItem[]) => {
            this.setDeferrableOnLoadBehavior(this.categories, items);
        })
        .Defer(() => of([]))
        .Transform(categories => categories.map(category => category.id))
        .RestoreWithId(false)
        .Icon('fas fa-box');

    dayparts = daypartsFilter(this.settings)
        .SetName('dayparts')
        .OnChange((items: DaypartLookupItem[]) => {
            this.setDeferrableFiltersCascadingBehavior(this.dayparts, items);
        })
        .OnLoad((items: DaypartLookupItem[]) => {
            this.setDeferrableOnLoadBehavior(this.dayparts, items);
        })
        .Defer(() => of([]))
        .RestoreWithId(false)
        .InFilterSet(false);

    spotLengths = spotLengthsFilter()
        .SetName('spotLengths')
        .OnChange((items: LookupItem[]) => {
            this.setDeferrableFiltersCascadingBehavior(this.spotLengths, items);
        })
        .OnLoad((items: LookupItem[]) => {
            this.setDeferrableOnLoadBehavior(this.spotLengths, items);
        })
        .Defer(() => of([]))
        .RestoreWithId(false)
        .Required(false)
        .InFilterSet(false);

    orderDetailOption = new SelectFilter<LookupItem>('Order Detail Option')
        .Defer(() => this.lookupSvc.getOrderDetailOptions())
        .OnLoad((items: LookupItem[]) => {
            this.orderDetailOption.Default(items.find(item => item.name === 'Combine Orders'));
        })
        .RestoreWithId(false)
        .InFilterSet(false);

    spotTypes = spotTypesFilter()
        .SetName('spotTypes')
        .Defer(() => of([]))
        .RestoreWithId(false)
        .InFilterSet(false);

    priorityCodes = new MultiSelectFilter<LookupItem>('Priority Codes')
        .SetName('priorityCodes')
        .Defer(() => this.advertiserInsightsSvc.getPriorityCodes())
        .OnLoad((items: LookupItem[]) => {
            if (!(this.priorityCodes.Value.length > 0)) {
                this.priorityCodes.Default(items);
            }
        })
        .Icon('fas fa-bullseye')
        .Transform(priorityCodes => priorityCodes.map(priorityCode => priorityCode.id))
        .RestoreWithId(false)
        .InFilterSet(false);

    startDate = startDateFilter()
        .OnChange((date: Moment) => {
            this.setDateFilterCascadingBehavior(this.startDate, 'startDate', date);
        })
        .Filter((date: Moment) => date?.day() === MONDAY);

    endDate = endDateFilter()
        .OnChange((date: Moment) => {
            this.setDateFilterCascadingBehavior(this.endDate, 'endDate', date);
        })
        .ResetDate((date: Moment) => {
            if (date && this.startDate.Value && date < moment(this.startDate.Value)) {
                date.date(moment(this.startDate.Value).date());
                date.month(moment(this.startDate.Value).month());
                date.year(moment(this.startDate.Value).year());
                date.day(NEXT_SUNDAY);
            }
            return date;
        })
        .Filter((date: Moment) => {
            if (date < moment(this.startDate.Value)) {
                return false;
            }
            return date?.day() === SUNDAY;
        });

    orderNumbers = new MultiSelectFilter<StringLookupItem>('Order Numbers')
        .SetName('orderNumbers')
        .OnChange((items: StringLookupItem[]) => {
            this.setDeferrableFiltersCascadingBehavior(this.orderNumbers, items);
        })
        .OnLoad((items: StringLookupItem[]) => {
            this.setDeferrableOnLoadBehavior(this.orderNumbers, items);
        })
        .Defer(() => of([]))
        .Filterable(true)
        .Transform(orderNumbers => orderNumbers.map(orderNumber => orderNumber.id))
        .RestoreWithId(false)
        .ShowPopupButton('See Order Details', () => {
            if (!this.orderNumbers.buttonIsDisabled) {
                const orders = this.matDialog.open(AdvertiserInsightsOrdersComponent, {
                    maxWidth: '100em !important',
                    width: 'fit-content',
                    data: {
                        orders: {
                            options: this.orderNumbers.options,
                            selected: this.orderNumbers.Value,
                        },
                        channels: this.channels.Value.map(channel => channel.id),
                        config: this.config,
                    },
                    maxHeight: '100vh',
                });

                orders.afterClosed().subscribe((selectedOrders: string[]) => {
                    if (selectedOrders) {
                        this.loadAfterCascade = true;
                        this.orderNumbers.Value = this.orderNumbers.options.filter(order => selectedOrders.includes(order.id));
                    }
                });
            }
        }, 'Please select advertisers before selecting orders', true)
        .Icon('fas fa-building');

    noChargeOption = new SelectFilter<LookupItem>('No Charge Option')
        .Defer(() => this.lookupSvc.getNoChargeOptions())
        .OnLoad((items: LookupItem[]) => {
            this.noChargeOption.Default(items.find(item => item.name === 'Include on Separate Row'));
        })
        .RestoreWithId(false)
        .InFilterSet(false);

    // changed Key from Show Aired Stats. Not changing variable name due potential breaking of saved filters.
    airStats = new BooleanFilter('Show Clearance')
        .Default(false)
        .InFilterSet(false);

    agencyBenchmark = new BooleanFilter('Show Agency Benchmark')
        .Default(false)
        .InFilterSet(false)
        .Toggle(!this.config.roleCapability.rate_optics.advertiser_insights?.agency_benchmark);

    categoryBenchmark = new BooleanFilter('Show Category Benchmark')
        .Default(false)
        .InFilterSet(false)
        .Toggle(!this.config.roleCapability.rate_optics.advertiser_insights?.category_benchmark);

    stationBenchmark = new BooleanFilter('Show Station Benchmark')
        .Default(false)
        .InFilterSet(false)
        .Toggle(!this.config.roleCapability.rate_optics.advertiser_insights?.station_benchmark);

    updateStations(): void {
        const newOptions = this.stationItems.filter(i =>
            this.markets.Value.map(market => market.id).includes(i.marketId)
            && this.channelGroup.Value.some(cg => i.channelGroups.includes(cg.id)));
        this.stations.Options(newOptions);
        this.stations.Default(newOptions);
    }

    updateChannels(): void {
        const newOptions = this.channelItems.filter(i =>
            this.stations.Value.map(item => item.id).includes(i.stationId)
            && this.channelGroup.Value.map(cg => cg.id).includes(i.channelGroup));
        this.channels.Options(newOptions);
        this.channels.Default(newOptions);
    }

    getMetricKey(): string {
        return UrlStore.localStorageKeys.advertiserMetrics;
    }

    getStationMetricKey(): string {
        return UrlStore.localStorageKeys.advertiserStationMetrics;
    }

    getMetricOrderKey(): string {
        return UrlStore.localStorageKeys.advertiserMetricsOrder;
    }

    getMetricsAndStationMetricsFromLocalStorage(): string[] {
        const metrics = this.lookupSvc.getFilterFromLocalStorage(this.getMetricKey());
        const stationMetrics = this.lookupSvc.getFilterFromLocalStorage(this.getStationMetricKey());
        this.renameStationMetricFields(stationMetrics);
        return [...metrics, ...stationMetrics];
    }

    renameStationMetricFields(stationMetrics: string[]): void {
        const advStationMetricMapping = {
            advStation_pctInventory: 'advistation_pctInventory',
            advStation_rateIndex: 'advistation_rateIndex',
        };

        stationMetrics.forEach((stationMetric, index) => {
            if (stationMetric in advStationMetricMapping) {
                stationMetrics[index] = advStationMetricMapping[stationMetric];
            }
        });
    }

    excludedFiltersFromSelectAll =  ['advertisers', 'agencies', 'markets', 'channelGroup', 'stations', 'channels'];

    removedFiltersFromSavedReports = ['orderNumbers'];

    metricsData = {
        metricsKey: this.getMetricKey(),
        metricsOrderKey: this.getMetricOrderKey(),
    };

    save = () => saveReport(this.matDialog, UrlStore.screenNames.advertiserInsights, this, this.lookupSvc, this.snackBar, this.metricsData, '', '', true, true, GetTemplateValueForSaveReport(this, this.excludedFiltersFromSelectAll, this.removedFiltersFromSavedReports, true), this.filterSet.allFiltersSelectedCheck());
    load = () => loadReport(this.matDialog, UrlStore.screenNames.advertiserInsights, this, this.lookupSvc, this.getMetricKey(), this.getMetricOrderKey(), '', '', this.afterLoad);


    setDeferrableFiltersCascadingBehavior(filter, items): void {
        if (this.savedReportLoaded) {
            return;
        }
        const filterName = filter.name;
        if (filter.showPopupButton) {
            filter.buttonIsDisabled = filter.options.length === 0 ? true : null;
            filter.buttonTitle = filter.options.length > 0 ? '' : 'No orders available. Please select different filters';
        }
        if (!filter.isOpened && !this.lockFilters && items && items.length > 0) {
            // Lock the onchange of the dependent filters until this has set them all
            // Don't request data if this filter has no selected options
            if (filter.Value.length > 0) {

                const filterChildren = this.getFilterChildren(filterName);

                filterChildren.forEach((filterChildName => {
                    this[filterChildName].isLoading = true;
                    this[filterChildName].isLoaded = false;
                }));

                if (filterChildren.length > 0) {
                    this.loadCascadingFilter(filterName, items);
                }
            }
        }
    }

    loadCascadingFilter(filterName, items): void {
        this.lockFilters = true;
        const cascadingFilterData = this.getCascadingFilterData(filterName, items);
        this.advertiserInsightsSvc.getCascadingFilters(cascadingFilterData)
            .subscribe(
                (data: AdvertiserInsightsDependentFiltersResponse) => {
                    const throwError = this.shouldThrowNoDataWarning(data);
                    if (throwError) {
                        this.noDataAvailableWarning();
                    }
                    this.loadChildFilters(filterName, data);
                },
                error => {
                    this.failedToLoadFilters(filterName, error);
                },
            );
    }

    shouldThrowNoDataWarning(data: AdvertiserInsightsDependentFiltersResponse): boolean {
        let throwError = false;
        Object.values(data).forEach(datum => {
            if (datum === null || datum.length === 0) {
                throwError = true;
            }
        });
        return throwError;
    }


    setDeferrableOnLoadBehavior(filter, items): void {
        this.lockFilters = true;
        filter.Default(items);
        filter.Options(items);
        this.lockFilters = false;
    }

    setDateFilterCascadingBehavior(filter, filterName, date): void {
        if (this.savedReportLoaded) {
            return;
        }
        if (!this.lockFilters && date) {
            this.lockFilters = true;
            this.getFilterChildren(filterName).forEach((filterChildName => {
                this[filterChildName].isLoading = true;
                this[filterChildName].isLoaded = false;
            }));
            if (filterName === 'startDate') {
                this.endDate.Value = this.startDate.Value <= this.endDate.Value ?
                    this.endDate.Value : this.startDate.Value.clone().add(6, 'day');
            }
            const cascadingFilterData = this.getCascadingFilterData(filterName, date);
            this.advertiserInsightsSvc.getCascadingFilters(cascadingFilterData)
                .subscribe(
                    (data: AdvertiserInsightsDependentFiltersResponse) => {
                        if (data.orderNumbers.length === 0) {
                            this.noDataAvailableWarning();
                        }
                        this.loadChildFilters(filterName, data);
                    },
                    error => {
                        this.failedToLoadFilters(filterName, error);
                    },
                );
        }
    }

    failedToLoadFilters(filterName, error): void {
        console.error(error);
        this.snackBar.openSnackBar('Failed to load filters', 'error');
        this.loadChildFilters(filterName, {});
    }

    noDataAvailableWarning(): void {
        this.snackBar.openSnackBar('No data available during this time frame, ' +
            'please adjust the start and end times to return data', 'warning');
    }

    updateSavedReportCascadingFilters(data: AdvertiserInsightsDependentFiltersResponse, values): void {
        const cascadingFilters = ['agencies', 'categories', 'dayparts', 'orderNumbers', 'spotTypes', 'spotLengths'];
        cascadingFilters.forEach(cascadingFilter => {
            this[cascadingFilter].Options(data[cascadingFilter]);
            let filterValues;
            if (values[cascadingFilter] === undefined || values[cascadingFilter] === 'ALL') {
                filterValues = this[cascadingFilter].options;
            } else {
                filterValues = this[cascadingFilter].options.filter(option =>
                    this[cascadingFilter].restoreWithId ? values[cascadingFilter].map(i => i.id).includes(option.id) :
                        values[cascadingFilter].includes(option.id));
                if (filterValues.length === 0) {
                    filterValues = this[cascadingFilter].options;
                }
            }
            this[cascadingFilter].setValueNoOnChange(filterValues);

        });
    }

    loadChildFilters(type: string, data: AdvertiserInsightsDependentFiltersResponse): void {
        // Each filter propagates to its child filters.
        const children = this.getFilterChildren(type);
        let continueCascade = true;
        let lastCascade: Filter<unknown>;
        children.forEach((filterChildName => {
            this[filterChildName].isLoading = false;
            this[filterChildName].isLoaded = true;
            if (continueCascade) {
                if (data[filterChildName]) {
                    const childFilter: Filter<unknown> = this[filterChildName];
                    if (childFilter instanceof MultiSelectFilter) {

                        continueCascade = cascadeToFilter(childFilter, data[filterChildName]);
                        lastCascade = childFilter;
                    } else if (childFilter instanceof DateFilter) {
                        if (!childFilter.Value) {
                            this.startDate.Value = getBroadcastYearStart(moment());
                            this.endDate.Value = getBroadcastYearEnd(moment());
                            lastCascade = this.endDate;
                            continueCascade = false;
                        } else {
                            if (childFilter.Value.format('YYYY-MM-DD') !== data[filterChildName]) {
                                continueCascade = false;
                                lastCascade = childFilter;
                            }
                        }
                    }
                }
            }
        }));

        this.lockFilters = false;
        if (lastCascade) {
            // self assign needed to force filter refresh
            // eslint-disable-next-line no-self-assign
            lastCascade.Value = lastCascade.Value;
        }
        if (continueCascade && this.loadAfterCascade) {
            this.loadAfterCascade = false;
            this.onLoadCallback();
        }
    }

    getFilterChildren(filterName: string): string[] {
        const children = [];
        let parentFound = false;
        this.cascadingFilters.forEach(filter => {
            if (parentFound) {
                children.push(filter);
            }
            if (filter === filterName) {
                parentFound = true;
            }
        });
        return children;
    }

    getFilterParents(filterName: string): string[] {
        const parents = [];
        for (const filter of this.cascadingFilters) {
            if (filter === filterName) {
                if (filter === 'startDate') {
                    parents.push('endDate');  // If start date is changed, always filter by end date
                }
                return parents;
            }
            parents.push(filter);
        }
        return parents;
    }

    parentFiltersHaveData(type: string): boolean {
        // Used to determine whether to use the server string matching for filters.
        // Since we cascade down filters, we don't want to allow global search for
        // filters with parents that already have options.
        const parents = this.getFilterParents(type);
        let parentsHaveData = false;
        parents.forEach((parentFilterName => {
            if (this[parentFilterName].Value.length > 0) {
                parentsHaveData = true;
            }
        }));
        return parentsHaveData;
    }

    getCascadingFilterData(
        filterName: string,
        filterValue: unknown,
    ): AdvertiserInsightsDependentFiltersRequest {
        // Child filters inherit their parent's filters.
        const filterData: AdvertiserInsightsDependentFiltersRequest = {};
        // Map the selected filter items to its param.
        filterData[filterName] = this[filterName].transform(filterValue);
        // Map parent filter items to their params.
        const parents = this.getFilterParents(filterName);
        parents.forEach((parentFilterName => {
            const parentFilter: Filter<unknown> = this[parentFilterName];
            filterData[parentFilterName] = parentFilter.transform(parentFilter.Value);
        }));
        filterData.channels = this.channels.Value.map(channel => channel.id);
        return filterData;
    }

    makeAdvertiserFilterServerSide(params: () => { [key: string]: unknown }): Observable<StringLookupItem[]> {
        this.advertisers.SetFilterFn((filterString: string) => this.lookupSvc.getAdvertiserInsightsAdvertisers({
            count: 500,
            searchText: filterString,
            ...params(),
        }));
        this.advertisers.IsServerFilter(true);
        return of([]);
    }
}
