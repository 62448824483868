import { inject } from '@angular/core';
import { Route, UrlSegment, Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { UrlStore } from '@shared/helpers/constants/url-store';
import { RateOpticsProductConfig } from '@models/config';


export const     canMatch = (
    route: Route,
    segments: UrlSegment[],
): boolean => hasRateOpticsAccess(segments.map(segment => segment.path).join('/'));

const hasRateOpticsAccess = (url: string): boolean => {
    const auth = inject(AuthService);
    const router = inject(Router);

    const isConfigured = auth.isConfigured();
    if (!isConfigured) {
        auth.beginLogin(url);
        return false;

    } else {

        let canActivate = false;
        const splitRoute = url.split('/');
        if (splitRoute[0] === '') {
            splitRoute.splice(0, 1);
        }
        const config = auth.getConfig();
        canActivate = canAccessRateOptics(
            splitRoute,
            config.roleCapability.rate_optics,
            config.customerId,
            config.userRole,
        );

        if (!canActivate) {
            router.navigate([UrlStore.ui.accessDenied]);
            return false;
        } else {
            return true;
        }
    }
};


const canAccessRateOptics = (url: string[], product: RateOpticsProductConfig, customerName: string, userRole: string): boolean => {
    switch (url.join('/')) {
    case 'RateOptics': {
        return product.rate_card.access;
    }
    case 'RateOptics/DynamicRateCard': {
        return product.rate_card.access;
    }
    case 'RateOptics/PricingHub': {
        return product.rate_card.access;
    }
    case 'RateOptics/Dashboard': {
        return product.dashboard.access;
    }
    case 'RateOptics/RateGenerator': {
        return product.rate_generator.access;
    }
    case 'RateOptics/PoliticalLUR': {
        return product.political_lur.access;
    }
    case 'RateOptics/AdvertiserInsights': {
        return product.advertiser_insights.access;
    }
    case 'RateOptics/InventoryInsights': {
        return product.inventory_insights.access;
    }
    case 'RateOptics/InventoryInsights/V2': {
        return product.inventory_insights.access;
    }
    case 'RateOptics/RatePerformance': {
        return product.rate_performance.access;
    }
    case 'RateOptics/BulkRates': {
        return product.bulk_rates.access;
    }
    case 'RateOptics/Aida': {
        return product.aida.access;
    }
    case '' || undefined: {
        return product.rate_card.access;
    }
    default: {
        return false;
    }
    }
};
