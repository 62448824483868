<app-dialog-container [loading]="false" (closeEvent)="closeDialogFalse()" [title]="'Configure Emailed Report'" data-cy="load-email-report-dialog-container">
    <div class="ra-primary-blue has-text-centered label-wrapper">
        <label class="has-text-weight-semibold">Configure Emailed Report</label>
    </div>
    <app-filter-input *ngIf="frequencyFilter" [property]="frequencyFilter"></app-filter-input>
    <app-filter-input *ngIf="isMonthly" [property]="firstDayOfTheMonth"></app-filter-input>
    <app-filter-input *ngIf="(isMonthly && !firstDayOfTheMonth.Value)" [property]="weekOfMonth"></app-filter-input>
    <app-filter-input *ngIf="isWeekly || (isMonthly && !firstDayOfTheMonth.Value)" [property]="dayOfWeekFilter"></app-filter-input>
    <app-filter-input *ngIf="isDaily || isWeekly || isMonthly" [property]="timeFilter"></app-filter-input>
    <div class="level-right">
            <a class="button is-primary apply-button" (click)="onSaveFilters()" [attr.disabled]="!validateFilters()?true:null">Save</a>
    </div>
</app-dialog-container>
