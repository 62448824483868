import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckBoxCellParams } from '@models/ag-grid';

@Component({
    selector: 'app-checkbox-cell',
    templateUrl: './checkbox-cell.component.html',
    styleUrls: ['./checkbox-cell.component.scss'],
})
export class CheckboxCellComponent implements ICellRendererAngularComp {

    public params: CheckBoxCellParams;
    checked: boolean;
    disabled = false;

    constructor() {
    }

    agInit(params: CheckBoxCellParams): void {
        this.params = params;
        this.checked = params.value;
        this.disabled = params.updateDisabled ? params.updateDisabled(params) : false;
    }

    change(event: MatCheckboxChange) {
        this.params.node.data[this.params.colDef.field] = event.checked;
        this.params?.callBack(this.params);
    }

    refresh(params: ICellRendererParams) {
        this.checked = params.value;
        return true;
    }
}
