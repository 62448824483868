<div *ngIf="!isLoaded">
    <strong class="mat-toolbar-row margin-top-75-em">
        Loading Filters...
    </strong>
</div>

<div *ngIf="isLoaded" class="is-flex filter-set-wrapper padding-75-em">
    <div class="filter-list">
        <ng-container *ngFor="let prop of properties">
            <div *ngIf="prop.isColumn && prop.inFilterSet" class="is-half">
                <app-filter-input  [property]="prop" [overrideDisabled]="prop.isReadonly"></app-filter-input>
            </div>
            <div *ngIf="!prop.isColumn && prop.inFilterSet && !prop.optionsDeletable">
                <app-filter-input [property]="prop" [overrideDisabled]="prop.isReadonly"></app-filter-input>
            </div>
            <div *ngIf="!prop.isColumn && prop.inFilterSet && prop.optionsDeletable">
                <app-filter-input [property]="prop" [overrideDisabled]="prop.isReadonly" (optionDeleteButtonClicked)="openDeleteOptionConfirmation($event)"></app-filter-input>
            </div>
            <ng-container *ngIf="prop.dynamicFilterParent">
                <ng-container *ngTemplateOutlet="dynamicFilters" ></ng-container>
            </ng-container>
        </ng-container>
    </div>

    <div class="is-flex width-100-pct">
        <button class="button is-primary apply-button" (click)="onApplyFilters()"
           [attr.disabled]="!allFiltersSelectedCheck() ? true : null"
           [ngClass]="{'button-highlight': (!isFiltersSame && highlightApplyButton && lastApplied)}">
            <span class="icon">
                <i *ngIf="!isFiltering" class="fas fa-filter has-text-white" aria-hidden="true"></i>
                <img *ngIf="isFiltering" src="assets/images/loader.svg" class="is-size-5"/>
            </span>
            <span class="has-text-white"> {{ applyButtonName }}</span>
        </button>
    </div>
    <div class="is-flex width-50-pct">
        <button class="button is-danger reset-button" (click)="onResetFilters()" [attr.disabled]="isFiltering ? true : null">
            <span class="icon">
                <i *ngIf="!isFiltering" class="fas fa-eraser has-text-white" aria-hidden="true"></i>
                <img *ngIf="isFiltering" src="assets/images/loader.svg" class="is-size-5"/>
            </span>
            <span class="has-text-white">Clear Data</span>
        </button>
    </div>
    <div class="is-flex cursor box width-100-pct margin-top-75-em" *ngIf="showReports">
        <span class="margin-right-half-em">Saved Report Options:</span>
        <button *ngIf="template.hasOwnProperty('save')"
           (click)="onSaveFilters()"
           title="Save current search option selections so you can quickly access them later">
            <span class="icon margin-left-half-em margin-right-half-em saved-options-icon">
                <i class="fa-2x fas fa-save" aria-hidden="true"></i>
            </span>
        </button>
        <button (click)="onLoadFilters()"
           [attr.disabled]="isFiltering ? true : null"
           title="Load saved search options so you can quickly load recently searched data">
            <span class="icon margin-left-half-em margin-right-half-em saved-options-icon">
                <i class="fa-2x margin-left-half-em fas fa-folder-open" aria-hidden="true"></i>
            </span>
        </button>
    </div>

</div>

<ng-template #dynamicFilters>
    <ng-container *ngFor="let dprop of dynamicProperties">
        <div *ngIf="dprop.isColumn && dprop.inFilterSet" class="is-half">
            <app-filter-input [property]="dprop" [overrideDisabled]="dprop.isReadonly"></app-filter-input>
        </div>
        <div *ngIf="!dprop.isColumn && dprop.inFilterSet">
            <app-filter-input [property]="dprop" [overrideDisabled]="dprop.isReadonly"></app-filter-input>
        </div>
    </ng-container>
</ng-template>
