<app-dialog-container [loading]="false" (closeEvent)="closeDialog()" data-cy="copy-url-dialog-container">
<div class="is-flex align-center justify-center break-newline has-text-centered">
    <div class="level">
        <div class="level-left">
            <div class="level-item">
                {{data.url}}
            </div>
        </div>
        <div class="level-right">
            <div class="level-item">
                <a class="button level-item is-primary" (click)="copyToClipboard()">Copy</a>
            </div>
        </div>
    </div>
</div>
</app-dialog-container>
